<template>
    <div class="buy-sell">
      <div
        class="buy-sell-result"
        v-loading="searchLoad"
        element-loading-background="rgba(34, 34, 34, 0.8)"
      >
        <div class="missing-div bottles-results">
          <div
            class="bottles-missing"
            v-if="getProducts['bottlerPage'].length === 0"
          >
            <div class="find-products">
              <p>{{ $t('search.empty.missing') }}</p>
              <button @click="dialogMissingBottle = true" class="missing-bottle-button">
                {{ $t('search.empty_bottle') }}
              </button>
            </div>
  
            <div class="filter-by">
              <button v-if="conditionsSearch > 0" @click="resetFilters()" class="btn-reset-filters">
                <img src="@/assets/svg/v2/filterReset.svg" alt="Reset filters" />
                {{ $t('commons.reset') }}
              </button>
  
              <button @click="filterModal = !filterModal">
                <img src="@/assets/svg/v2/filter.svg" alt="Filter by" />
                {{ $t('search.filter_by') }}
              </button>
  
              <CommonModal
                v-if="filterModal"
                size="small"
                :modalTitle="$t('filters.title')"
                @close="filterModal = false"
              >
                <searchFilters
                  @close="filterModal = false"
                  @search="searchProductsWithFilters"
                />
              </CommonModal>
            </div>
          </div>
  
          <div v-if="getProducts['bottlerPage'].length === 0 && history.length === 0 && getFiltersHistoryBottlerPage" class="current-filters-no-results">
            <el-tag v-for="(c, i) in getSpiritTypesSearch" :key="i" size="large" color="#a0a0a032" effect="dark" class="tag-style">{{ c }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.age" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.age, 'age')">{{ getFiltersHistoryBottlerPage.age }}Y</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.vintage" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.vintage, 'vintage')">{{ getFiltersHistoryBottlerPage.vintage }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.single_cask" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.single_cask, 'single_cask')">{{ getFiltersHistoryBottlerPage.single_cask ? 'Single Cask': '' }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.spirit_type" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.spirit_type, 'spirit_type')">{{ spiritTypeLabel(getFiltersHistoryBottlerPage.spirit_type) }}</el-tag>
            <el-tag v-for="(c, i) in returnFiltersCountry" :key="i" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(c, 'country')">{{ countryName(c) }}</el-tag>
          </div>
        </div>
  
        <div
          v-if="getProducts['bottlerPage'].length === 0"
          class="empty-bottle"
        >
          <div>
            <img
              class="background"
              src="@/assets/svg/v2/home/background.svg"
              alt="TEP background"
            />
            <img src="@/assets/svg/v2/bottle.svg" alt="Bottle" />
            <p>{{ $t('search.no_result') }}</p>
          </div>
        </div>
  
        <div class="bottles-results" v-else>
          <div class="bottles-results-header">
            <div class="find-products">
              <p>
                {{ getProducts['bottlerPage'].length }} {{ findProductsLabel }}
              </p>
              <p class="total-product">
                ({{ getProducts['bottlerPage'].length === 200 ? '+' : ''
                }}{{ getProductsTotal['bottlerPage'] }}
                {{ findTotalProductsLabel }})
              </p>
              <button
                @click="dialogMissingBottle = true"
                class="missing-bottle-button"
              >
                {{ $t('search.empty_bottle') }}
              </button>
            </div>
            <div class="filter-by">
              <button v-if="conditionsSearch > 0" @click="resetFilters()" class="btn-reset-filters">
                <img src="@/assets/svg/v2/filterReset.svg" alt="Reset filters" />
                {{ $t('commons.reset') }}
              </button>
  
              <button @click="filterModal = !filterModal">
                <img src="@/assets/svg/v2/filter.svg" alt="Filter by" />
                {{ $t('search.filter_by') }}
              </button>
  
              <CommonModal
                v-if="filterModal"
                size="small"
                :modalTitle="$t('filters.title')"
                @close="filterModal = false"
              >
                <searchFilters
                  @close="filterModal = false"
                  @search="searchProductsWithFilters"
                ></searchFilters>
              </CommonModal>
            </div>
          </div>
  
          <div class="current-filters">
            <el-tag v-for="(c, i) in getSpiritTypesSearch" :key="i" size="large" color="#a0a0a032" effect="dark" class="tag-style">{{ c }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.age" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.age, 'age')">{{ getFiltersHistoryBottlerPage.age }}Y</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.vintage" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.vintage, 'vintage')">{{ getFiltersHistoryBottlerPage.vintage }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.single_cask" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.single_cask, 'single_cask')">{{ getFiltersHistoryBottlerPage.single_cask ? 'Single Cask': '' }}</el-tag>
            <el-tag v-if="getFiltersHistoryBottlerPage.spirit_type" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(getFiltersHistoryBottlerPage.spirit_type, 'spirit_type')">{{ spiritTypeLabel(getFiltersHistoryBottlerPage.spirit_type) }}</el-tag>
            <el-tag v-for="(c, i) in returnFiltersCountry" :key="i" size="large" color="#a0a0a032" effect="dark" class="tag-style" closable @close="closeFilterTag(c, 'country')">{{ countryName(c) }}</el-tag>
          </div>
  
          <div class="bottles-results-table">
            <el-table
              :data="getProducts['bottlerPage']"
              stripe
              @cell-mouse-enter="enterTrack"
              @cell-mouse-leave="leaveTrack"
              @row-click="seeProduct"
              class="bottles-list"
              >
              <el-table-column
                prop="ticker_maitre"
                label="Ticker"
                width="150"
                sortable
                :sort-method="customSortTickerMaitre"
              >
                <template v-slot="{ row }">
                  <button v-if="row.ticker_maitre" class="ticker-maitre">{{ row.ticker_maitre }}</button>
                  <p v-else class="ticker-maitre-empty">-</p>
                </template>
              </el-table-column>
  
              <el-table-column
                prop="ticker_composite"
                label="Ticker composite"
                width="190"
              >
                <template v-slot="{ row }">
                  <button
                    @click.stop="searchComposite(row.ticker_composite)"
                    class="ticker-composite"
                  >
                    <p v-if="row.ticker_composite">
                      {{ $t('search.see_composite_ticker') }}
                    </p>
                    <p v-else>-</p>
                    <img
                      v-if="row.ticker_composite"
                      src="@/assets/svg/v2/arrowToRight.svg"
                      alt="Create block trade"
                    />
                  </button>
                </template>
              </el-table-column>
  
              <el-table-column prop="title" :label="$t('search.name')" sortable>
                <template v-slot="{ row }">
                  <button v-if="row.is_bottler" class="bottle-name-list">{{ row.name }} : {{ $t('search.bottler_page') }}</button>
                  <button v-else class="bottle-name-list">{{ row.title }}</button>
                </template>
              </el-table-column>
  
              <el-table-column :label="$t('search.live_orders')" width="430px">
                <template v-slot="{ row }">
                  <div class="live-orders">
                    <div class="case-buy" @click="seeOrderProduct(row, 1)">
                      <button v-if="row.buy_orders" class="live-order-buy">
                        <img
                          src="@/assets/svg/v2/products/buyOrder.svg"
                          alt="Buy order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.buy') }}</p>
                        <span>({{ row.buy_orders }})</span>
                      </button>
                      <div v-else class="empty-order">
                        <img
                          src="@/assets/svg/v2/products/buyOrderInactive.svg"
                          alt="Buy order"
                        />
                        <p>{{ $t('search.buy') }}</p>
                      </div>
                    </div>
  
                    <div class="case-sell" @click="seeOrderProduct(row, 1)">
                      <button v-if="row.sell_orders" class="live-order-sell">
                        <img
                          src="@/assets/svg/v2/products/sellOrder.svg"
                          alt="Sell order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.sell') }}</p>
                        <span>({{ row.sell_orders }})</span>
                      </button>
                      <div v-else class="empty-order">
                        <img
                          src="@/assets/svg/v2/products/sellOrderInactive.svg"
                          alt="Sell order"
                        />
                        <p>{{ $t('search.sell') }}</p>
                      </div>
                    </div>
  
                    <div class="case-taker" @click="seeOrderProduct(row, 3)">
                      <button v-if="row.taker_orders" class="live-order-taker">
                        <img
                          src="@/assets/svg/v2/products/swapActive.svg"
                          alt="Swap taker order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.taker') }}</p>
                        <span>({{ row.taker_orders }})</span>
                      </button>
                      <div v-else class="empty-order">
                        <img
                          src="@/assets/svg/v2/products/swap.svg"
                          alt="Swap taker order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.taker') }}</p>
                      </div>
                    </div>
  
                    <div class="case-giver" @click="seeOrderProduct(row, 3)">
                      <button v-if="row.giver_orders" class="live-order-giver">
                        <img
                          src="@/assets/svg/v2/products/swapActive.svg"
                          alt="Swap giver order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.giver') }}</p>
                        <span>({{ row.giver_orders }})</span>
                      </button>
                      <div v-else class="empty-order">
                        <img
                          src="@/assets/svg/v2/products/swap.svg"
                          alt="Swap giver order"
                          width="16"
                          height="16"
                        />
                        <p>{{ $t('search.giver') }}</p>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
  
              <el-table-column
                prop=""
                :label="$t('search.blocktrade')"
                width="160px"
              >
                <template v-slot="{}">
                  <button class="bottle-block-trade">
                    <img
                      src="@/assets/svg/v2/products/blocktrade.svg"
                      alt="Create block trade"
                    />
                    <p>{{ $t('search.blocktrade') }}</p>
                    <img
                      src="@/assets/svg/v2/arrowToRight.svg"
                      alt="Create block trade"
                    />
                  </button>
                </template>
              </el-table-column>
            </el-table>
  
            <div class="products-tel">
              <div
                class="product-tel-card"
                @click="seeProduct(product)"
                v-for="(product, c) in getProducts['bottlerPage']"
                :key="c"
              >
                <h4>
                  <button v-if="product.ticker_maitre" class="ticker-maitre">
                    {{ product.ticker_maitre }}
                  </button>
                  {{ product.title || product.name }}
                  <span v-if="product.name"> : {{ $t('search.bottler_page') }}</span>
                </h4>
  
                <div class="product-tel-informations">
                  <button
                    @click.stop="searchComposite(product.ticker_composite)"
                    class="ticker-composite"
                  >
                    <p v-if="product.ticker_composite">
                      {{ $t('search.see_composite_ticker') }}
                    </p>
                    <img
                      v-if="product.ticker_composite"
                      src="@/assets/svg/v2/arrowToRight.svg"
                      alt="Create block trade"
                    />
                  </button>
                </div>
  
                <div class="live-orders">
                  <div class="case-buy">
                    <button v-if="product.buy_orders" class="live-order-buy">
                      <img
                        src="@/assets/svg/v2/products/buyOrder.svg"
                        alt="Buy order"
                        width="16"
                        height="16"
                      />
                      <span>({{ product.buy_orders }})</span>
                    </button>
                    <div v-else class="empty-order">
                      <img
                        src="@/assets/svg/v2/products/buyOrderInactive.svg"
                        alt="Buy order"
                      />
                      <p>(0)</p>
                    </div>
                  </div>
  
                  <div class="case-sell">
                    <button v-if="product.sell_orders" class="live-order-sell">
                      <img
                        src="@/assets/svg/v2/products/sellOrder.svg"
                        alt="Sell order"
                        width="16"
                        height="16"
                      />
                      <span>({{ product.sell_orders }})</span>
                    </button>
                    <div v-else class="empty-order">
                      <img
                        src="@/assets/svg/v2/products/sellOrderInactive.svg"
                        alt="Sell order"
                      />
                      <p>(0)</p>
                    </div>
                  </div>
  
                  <div class="case-taker">
                    <button v-if="product.taker_orders" class="live-order-taker">
                      <img
                        src="@/assets/svg/v2/products/swapActive.svg"
                        alt="Swap taker order"
                        width="16"
                        height="16"
                      />
                      <span>({{ product.taker_orders }})</span>
                    </button>
                    <div v-else class="empty-order">
                      <img
                        src="@/assets/svg/v2/products/swap.svg"
                        alt="Swap taker order"
                        width="16"
                        height="16"
                      />
                      <p>(0)</p>
                    </div>
                  </div>
  
                  <div class="case-giver">
                    <button v-if="product.giver_orders" class="live-order-giver">
                      <img
                        src="@/assets/svg/v2/products/swapActive.svg"
                        alt="Swap giver order"
                        width="16"
                        height="16"
                      />
                      <span>({{ product.giver_orders }})</span>
                    </button>
                    <div v-else class="empty-order">
                      <img
                        src="@/assets/svg/v2/products/swap.svg"
                        alt="Swap giver order"
                        width="16"
                        height="16"
                      />
                      <p>(0)</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="viewHistory()"
            v-if="history.length > 0"
            class="back-to-history"
          >
            <img src="@/assets/svg/v2/history.svg" alt="History" />
            <p>{{ $t('search.history_view') }}</p>
          </button>
        </div>
  
        <div id="mouse" v-if="current_row">
          <img v-if="!current_row.files_attachments" src="@/assets/svg/v2/bottle.svg" alt="Bottle" width="50" />
          <img v-else-if="current_row.files_attachments.length === 0" src="@/assets/svg/v2/bottle.svg" alt="Bottle" width="50" />
          <img v-else-if="current_row.files_attachments.length > 0" :src="current_row.files_attachments[0]" alt="Bottle picture preview"/>
        </div>
        <CommonModal
          v-if="dialogMissingBottle"
          :modalTitle="$t('search.empty.title')"
          size="small"
          @close="dialogMissingBottle = false"
        >
          <reportBottleMissing
            @close="dialogMissingBottle = false"
          ></reportBottleMissing>
        </CommonModal>
  
        <CommonModal
          v-if="dialogSearchNotAccess"
          :modalTitle="$t('search.connect_access_search')"
          size="small"
          @close="dialogSearchNotAccess = false"
        >
        <searchNotAccess @close="dialogSearchNotAccess = false" />
        </CommonModal>
      </div>
    </div>
  </template>
  
  <script>
  import reportBottleMissing from '@/components/modules/dialogs/reportBottleMissing.vue'
  import searchNotAccess from '@/components/modules/dialogs/searchNotAccess.vue'
  import searchFilters from '@/components/modules/dialogs/filters.vue'
  import CommonModal from '@/components/commons/modal.vue'
  import { mapActions, mapGetters } from 'vuex'
  import { find, findIndex } from 'lodash'
  import { ElMessage } from 'element-plus'
  
  export default {
    props: {
      spirittype: {
        type: Number,
        required: false,
        default: 24,
      },
      compare: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    components: {
      reportBottleMissing,
      searchNotAccess,
      CommonModal,
      searchFilters
    },
    data() {
      return {
        dialogMissingBottle: false,
        dialogSearchNotAccess: false,
        current_row: null,
        search: '',
        last_pending_search: '',
        prefill: this.$route.query.prefill || null,
        payload: {
          type: 'bottlerPage',
          search: '',
          filters: {
            spirit_type: null,
            country: null,
            age: null,
            vintage: null,
            single_cask: false
          },
          bottler: this.getBottler?.name
        },
        history: [],
        searchLoad: false,
        timer: null,
        filterModal: false,
        conditionsSearch: 0
      }
    },
    computed: {
      ...mapGetters({
        getProducts: 'getProducts',
        isAuth: 'getIsAuth',
        getProductsTotal: 'getProductsTotal',
        getSearchPending: 'getSearchPending',
        getLists: 'getLists',
        getSearchHistory: 'getSearchHistory',
        getFiltersBottlerPage: 'getFiltersBottlerPage',
        getFiltersHistoryBottlerPage: 'getFiltersHistoryBottlerPage',
        getBottler: 'getBottler'
      }),
      findProductsLabel() {
        return this.getProducts['bottlerPage'].length < 2
          ? this.$t('search.find_product')
          : this.$t('search.find_products')
      },
      findTotalProductsLabel() {
        return this.getProductsTotal['bottlerPage'] < 2
          ? this.$t('search.total_product')
          : this.$t('search.total_products')
      },
      returnSearchProducts() {
        return this.getProducts['bottlerPage']
      },
      returnFiltersCountry() {
        if (this.getFiltersHistoryBottlerPage) {
          if (this.getFiltersHistoryBottlerPage.country) {
            return this.getFiltersHistoryBottlerPage.country;
          }
          return [];
        }
        return [];
      },
      getSpiritTypesSearch() {
        const spiritLabels = [];

        this.returnSearchProducts.forEach((row) => {
          const findInList = find(this.getLists.spirit_type, (o) => {
            return o.contain.includes(row.spirit_type)
          })

          if (findInList) {
            if (!spiritLabels.includes(findInList.label)) {
              spiritLabels.push(findInList.label);
            }
          }
        })

        return spiritLabels;
      }
    },
    watch: {
      getSearchHistory() {
        this.history = []
        if (this.getSearchHistory.length > 0) {
          this.getSearchHistory.forEach((o) => {
            this.history.push(o)
          })
        }
  
        this.history = this.history.reverse()
      },
      isAuth() {
        if (this.isAuth) {
          this.searchHistory();
          this.filtersList();
        }
      }
    },
    mounted() {
      if (this.getProducts['bottlerPage'].length > 0 && this.search.length === 0) {
  
        if (this.getSearchHistory) {
          const replacefirstElement = this.getSearchHistory.reverse();
  
          if (replacefirstElement[0].total > 0) {
            const payload = {
              search: replacefirstElement[0].search
            }
            
            this.searchProducts(payload, 'bottlerPage');
          }
        }
      }
  
      window.addEventListener('searchHistory-localstorage-changed', (event) => {
        this.history = event.detail.storage
      })
  
      document.onmousemove = function (e) {
        let mouseTrackDiv = document.getElementById('mouse')
        let xMousePos = e.clientX + window.pageXOffset
        let yMousePos = e.clientY + window.pageYOffset
  
        if (mouseTrackDiv) {
          mouseTrackDiv.style.top = yMousePos + 18 + 'px'
          mouseTrackDiv.style.left = xMousePos + 18 + 'px'
        }
      }
  
      if (this.isAuth) {
        this.searchHistory();
        this.filtersList();
        this.searchProducts(this.payload, 'bottlerPage');
        
        if (this.getFiltersHistoryBottlerPage) {
          this.payload.filters = this.getFiltersHistoryBottlerPage;
          this.searchProducts(this.payload, 'bottlerPage');
        }
      }
    },
    methods: {
      ...mapActions({
        autoSearchAuth: 'autocompleteSearchAuth',
        searchHistory: 'searchHistory',
        delSearchHistory: 'delSearchHistory',
        delAllSearchHistory: 'delAllSearchHistory',
        filtersList: 'filtersList'
      }),
      returnFiltersCountryHistory(h) {
        if (h.filters) {
          if (h.filters.country) {
            return h.filters.country
          }
          return [];
        }
        return [];
      },
      searchProducts(searchProducts, key) {
        this.conditionsSearch = 0;
        this.search = searchProducts
  
        if (searchProducts.total) {
          this.payload.filters = searchProducts.filters;
        }
  
        this.payload.search = searchProducts.value ? searchProducts.value : searchProducts.search
        this.payload.spirit_type = searchProducts.spirit_type

        if (this.payload.search.length === 0) {
            this.payload.search = `${this.getBottler?.name} ${this.payload.search}`;
        } else {
            this.payload.search = this.getBottler?.name;
        }
  
        if (!this.payload.filters) {
          this.payload.filters = {
            spirit_type: null,
            country: null,
            age: null,
            vintage: null,
            single_cask: false
          }
        }
  
        if (searchProducts.value?.length > 0 || searchProducts.search?.length > 0) {
          this.conditionsSearch += 2;
        }
  
        if (this.payload.filters.age) {
          this.conditionsSearch += 1;
        }
  
        if (this.payload.filters.single_cask) {
          this.conditionsSearch += 1;
        }
  
        if (this.payload.filters.spirit_type) {
          this.conditionsSearch += 1;
        }
  
        if (this.payload.filters.vintage) {
          this.conditionsSearch += 1;
        }
  
        if (this.payload.filters.country) {
          if (this.payload.filters.country.length > 0) {
            this.conditionsSearch += 1;
          }
        }
  
        if (this.conditionsSearch === 1) {
          ElMessage({
            message: this.$t('filters.filters_conditions'),
            type: 'warning',
            timeout: 5000,
            grouping: true,
          })
        }
  
        if (this.conditionsSearch >= 2) {
          setTimeout(() => {
            if (!this.getSearchPending['bottlerPage']) {
              const formattedPayload = {
                type: key,
                search: searchProducts.value ? searchProducts.value : searchProducts.search,
                spirit_type: searchProducts.spirit_type,
                filters: this.payload.filters,
                bottler: this.getBottler?.name
              }
    
              this.last_pending_search = searchProducts
    
              if (this.isAuth === true && formattedPayload.search) {
                this.searchLoad = true
                this.autoSearchAuth(formattedPayload).then(() => {
                  this.searchLoad = false
                })
              } else {
                this.dialogSearchNotAccess = true
    
                ElMessage({
                  message: this.$t('search.connect_access_search'),
                  type: 'warning',
                  timeout: 5000,
                  grouping: true,
                })
              }
            }
          }, 400);
        } else {
          this.$store.commit('RESET_SEARCH', { key: key, products: [] })
        }
      },
      searchProductsWithFilters(filters) {
        this.payload.filters = filters;
        this.searchProducts(this.payload, 'bottlerPage');
      },
      removeHistory() {
        this.delAllSearchHistory()
      },
      viewHistory() {
        this.$store.commit('RESET_SEARCH', { key: 'bottlerPage', products: [] })
      },
      deleteHistoryItem(h) {
        const findHistory = findIndex(this.history.reverse(), (o) => {
          return o.search == h.search
        })
        if (findHistory !== -1) {
          this.delSearchHistory(findHistory)
        }
      },
      seeOrderProduct(product) {
        if (this.search?.value <= 0) {
          this.$store.commit('RESET_SEARCH', {
            key: 'bottlerPage',
            products: [],
          })
        }
        this.$router.push('/product/' + product.isbn + '?tab=' + 1)
        window.scrollTo(0, 0)
      },
      seeProduct(product) {
        if (this.search?.value <= 0) {
          this.$store.commit('RESET_SEARCH', { key: 'bottlerPage', products: [] })
        }

        if (product.is_bottler) {
          this.searchProducts(this.bottler.name, 'bottlerPage');
          this.$router.push('/bottler/' + product.name_concat);
        } else {
          this.$router.push('/product/' + product.isbn);
        }
        window.scrollTo(0, 0)
      },
      enterTrack(row) {
        this.current_row = row
  
        if (this.current_row.files_attachments) {
          setTimeout(() => {
            let mouseTrackDiv = document.getElementById('mouse')
            if (mouseTrackDiv) {
              mouseTrackDiv.style.display = 'flex'
            }
          }, 100)
        } else if (!this.current_row.files_attachments) {
          setTimeout(() => {
            let mouseTrackDiv = document.getElementById('mouse')
  
            if (mouseTrackDiv) {
              mouseTrackDiv.style.display = 'flex'
            }
          }, 100)
        }
      },
      leaveTrack() {
        if (this.current_row.files_attachments) {
          setTimeout(() => {
            let mouseTrackDiv = document.getElementById('mouse')
  
            if (mouseTrackDiv) {
              mouseTrackDiv.style.display = 'none'
            }
          }, 100)
        } else if (!this.current_row.files_attachments) {
          setTimeout(() => {
            let mouseTrackDiv = document.getElementById('mouse')
  
            if (mouseTrackDiv) {
              mouseTrackDiv.style.display = 'none'
            }
          }, 100)
        }
      },
      findTotalProductsLabelShort(h) {
        return h < 2
          ? this.$t('search.total_product_short')
          : this.$t('search.total_products_short')
      },
      searchComposite(ticker_composite) {
        const search_params = {
          spirit_type: this.payload.spirit_type,
          value: ticker_composite,
        }
  
        this.payload.search = ticker_composite
  
        this.searchProducts(search_params, 'bottlerPage')
      },
      customSortTickerMaitre(a, b) {
        const splitAlphaNum = (str) => {
          const match = str.toString().match(/(\D+)(\d+)?/);
          return {
            alpha: match[1] || '',
            num: match[2] ? parseInt(match[2], 10) : 0
          };
        };
  
        const aParts = splitAlphaNum(a.ticker_maitre);
        const bParts = splitAlphaNum(b.ticker_maitre);
  
        if (aParts.alpha < bParts.alpha) {
          return -1;
        }
        if (aParts.alpha > bParts.alpha) {
          return 1;
        }
        return aParts.num - bParts.num;
      },
      closeFilterTag(tag, tagName) {
  
        if (tagName === 'age') {
          this.payload.filters.age = null;
        }
  
        if (tagName === 'vintage') {
          this.payload.filters.vintage = null;
        }
  
        if (tagName === 'single_cask') {
          this.payload.filters.single_cask = false;
        }
  
        if (tagName === 'spirit_type') {
          this.payload.filters.spirit_type = null;
        }
  
        if (tagName === 'country') {
          const findCountryIndex = findIndex(this.getFiltersHistoryBottlerPage.country, (c) => {
            return c === tag;
          })
  
          if (findCountryIndex !== -1) {
            this.payload.filters.country = this.getFiltersHistoryBottlerPage.country.filter((element, i) => i !== findCountryIndex);
          }
        }
  
        this.searchProducts(this.payload, 'bottlerPage');
      },
      countryName(value) {
        if (this.getFiltersBottlerPage) {
          const countryLabel = find(this.getFiltersBottlerPage.country, (c) => {
            return c.value === value;
          })
    
          if (countryLabel) {
            return countryLabel.country
          } else {
            return 'N/A';
          }
        } else {
          return 'N/A';
        }
      },
      spiritTypeLabel(value) {
        const spiritLabel = find(this.getLists.spirit_type, (s) => {
          return s.value === value;
        })
  
        if (spiritLabel) {
          return spiritLabel.label
        } else {
          return 'N/A';
        }
      },
      resetFilters() {
        this.payload = {
          type: 'bottlerPage',
          search: this.getBottler?.name,
          filters: {
              spirit_type: null,
              country: null,
              age: null,
              vintage: null,
              single_cask: false
          },
          bottler: this.getBottler?.name
        }
  
        this.$store.commit('SET_FILTERS_HISTORY', null);
        this.searchProducts(this.payload, 'bottlerPage');
      }
    }
  }
  </script>
  
  <style lang="scss">
  @import '@/assets/styles/root.scss';
  
  .bottles-history,
  .bottles-history-actions,
  .bottles-results,
  .bottles-results-header,
  .find-products,
  .live-orders,
  .bottle-block-trade,
  .ticker-composite,
  .bottles-missing,
  .empty-order {
    display: flex;
  }
  
  .buy-sell {
    margin-bottom: 50px;
  }
  
  .missing-bottle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 8px;
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    border: none;
    background-color: #2667FF!important;
    margin-right: 5px;
  }
  
  .products-tel {
    display: none;
  }
  
  .bottles-missing {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 866.4px;
    margin-bottom: 15px;
  
    .find-products {
      align-items: center;
  
      p {
        color: white;
        font-size: 15px;
        font-weight: 600;
        margin-right: 16px;
        margin-bottom: 0;
      }
  
      .total-product {
        color: $grey40;
        font-weight: 400;
      }
  
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 8px;
        border-radius: 6px;
        font-weight: 400;
        font-size: 16px;
        border: none;
        background-color: rgba(160, 160, 160, 0.196);
        color: white;
        margin-right: 5px;
      }
    }
  
    .filter-by {
      display: flex;
  
      .btn-reset-filters {
        margin-right: 5px;
      }
  
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        border: 1px solid $border;
        background: #333;
        color: white;
        width: 116px;
        height: 36px;
        font-weight: 700;
        margin: auto;
  
        img {
          margin-right: 4px;
        }
      }
    }
  }
  
  .current-filters, .current-filters-no-results {
    display: flex;
    width: 100%;
    max-width: 1380px;
  }
  
  .current-filters-no-results {
    max-width: 866px;
    margin: auto;
    margin-bottom: 15px;
  }
  
  .current-filters, .current-filters-no-results {
  
    .el-tag {
      margin-right: 5px;
    }
  }
  
  .bottles-history {
    flex-direction: column;
    max-width: 912px;
    margin: 0 auto;
  
    .bottles-history-actions {
      justify-content: space-between;
      width: 95%;
      margin: 0 auto;
  
      h3 {
        color: white;
        font-size: 15px;
        font-weight: 600;
      }
  
      button {
        border: 0;
        color: $grey40;
        background: transparent;
        z-index: 1;
      }
    }
  }
  
  .borderBottom {
    border-bottom: 1px solid #ffffff3d;
  }
  
  .empty-bottle {
    margin-top: 150px;
    margin-bottom: 150px;
  
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
  
      img {
        z-index: 2;
      }
  
      .background {
        position: absolute;
        width: 800px;
        z-index: -1;
        bottom: -280px;
      }
  
      p {
        color: $subtitle;
        font-size: 16px;
        font-weight: 500;
        max-width: 315px;
        text-align: center;
        margin-top: 28px;
      }
    }
  }
  
  .cell {
    color: white;
  }
  
  .tag-style {
    color: white;
    border: none;
  }
  
  .bottles-results {
    flex-direction: column;
    align-items: center;
    margin-top: 5px;
  
    .bottles-results-header {
      justify-content: space-between;
      width: 100%;
      max-width: 1380px;
  
      .find-products {
        align-items: center;
  
        p {
          color: white;
          font-size: 15px;
          font-weight: 600;
          margin-right: 16px;
        }
  
        .total-product {
          color: $grey40;
          font-weight: 400;
        }
  
        button {
          color: white;
        }
      }
  
      .filter-by {
        display: flex;
  
        .btn-reset-filters {
          margin-right: 5px;
        }
  
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          border: 1px solid $border;
          background: #333;
          color: white;
          width: 116px;
          height: 36px;
          font-weight: 700;
  
          img {
            margin-right: 4px;
          }
        }
      }
    }
  
    .bottles-results-table {
      position: relative;
      margin-top: 15px;
      width: 100%;
  
      .bottles-list {
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: 0;
        background: #333;
        max-width: 1380px;
        cursor: pointer;
        margin: auto;
      }
  
      .result-no-auth {
        position: absolute;
        z-index: 5000;
        left: 50%;
        bottom: 0px;
        transform: translate(-50%, 0);
        border: 1px solid rgba(255, 255, 255, 0.12);
        border-top: 0;
        width: 100%;
        max-width: 1378px;
        margin: auto;
        padding: 37px 0;
        background: rgb(0, 0, 0);
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        left: 50%;
        backdrop-filter: blur(3px);
  
        .result-no-auth-content {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
  
          .result-no-auth-img {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: #555555;
            height: 42px;
            width: 42px;
            margin-bottom: 7px;
          }
  
          .result-no-auth-title {
            h3 {
              color: white;
              font-size: 16px;
              font-weight: 500;
            }
  
            button {
              display: flex;
              justify-content: center;
              align-items: center;
              background: transparent;
              border: 0;
              margin: auto;
              margin-top: 7px;
  
              p {
                color: #add7f6;
                font-size: 15px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
  
    .back-to-history {
      display: flex;
      align-items: center;
      margin-top: 50px;
      background: transparent;
      border: 0;
  
      p {
        color: white;
        margin-left: 5px;
      }
    }
  }
  
  .ticker-maitre {
    border-radius: 6px;
    border: 1px solid $border;
    color: $subtitle;
    background: transparent;
    padding: 3px;
  }
  
  .ticker-maitre-empty {
    color: $subtitle;
  }
  
  .ticker-composite {
    color: $subtitle;
    border: 0;
    background: transparent;
  
    p {
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
    }
  
    img {
      margin-left: 4px;
    }
  }
  
  .bottle-name-list {
    display: flex;
    text-align: start;
    word-break: keep-all;
    color: white;
    font-size: 15px;
    font-weight: 500;
    border: 0;
    background: transparent;
  }
  
  .empty-order {
    align-items: center;
  
    img {
      border-radius: 50px;
      background: #ffffff33;
      margin-right: 5px;
    }
  
    p {
      color: $grey40;
      font-size: 15px;
      font-weight: 500;
    }
  }
  
  .live-orders {
    justify-content: flex-start;
  
    .case-buy,
    .case-sell,
    .case-taker,
    .case-giver {
      width: 90px;
  
      .empty-order {
        margin-left: 10px;
      }
    }
  
    .case-sell {
      .empty-order {
        margin-left: 22px;
      }
    }
  
    .case-taker {
      width: 105px;
  
      .empty-order {
        margin-left: 33px;
      }
    }
  
    .case-giver {
      width: 105px;
      margin-left: 10px;
  
      .empty-order {
        margin-left: 33px;
      }
    }
  }
  
  .live-order-buy,
  .live-order-sell,
  .live-order-taker,
  .live-order-giver {
    display: flex;
    border-radius: 50px;
    border: 1px solid $border;
    background: #444;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
    height: 27px;
    min-width: 90px;
  }
  
  .live-order-buy,
  .live-order-sell,
  .live-order-taker,
  .live-order-giver {
    justify-content: center;
    align-items: center;
  
    img {
      background: #84cc16;
      border-radius: 50%;
    }
  
    p {
      color: white;
      font-size: 15px;
      font-weight: 500;
      margin-left: 6px;
    }
  
    span {
      color: $grey40;
      font-size: 15px;
      font-weight: 500;
      margin-left: 2px;
    }
  }
  
  .live-order-sell {
    margin-left: 12px;
  
    img {
      background: #f43f5e;
    }
  }
  
  .live-order-taker,
  .live-order-giver {
    margin-left: 24px;
    width: 102px;
  
    img {
      background: #a855f7;
    }
  }
  
  .live-order-giver {
    img {
      background: #2667ff;
    }
  }
  
  .bottle-block-trade {
    justify-content: center;
    align-items: center;
    border: 0;
    background: transparent;
  
    p {
      margin: 0 3px;
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
    }
  }
  
  #mouse {
    position: absolute;
    background: #fff;
    justify-content: center;
    z-index: 999;
    max-width: 170px;
    max-height: 170px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    overflow: hidden;
  
    img {
      max-width: 100%;
    }
  }
  
  .el-table tr,
  .el-table th.el-table__cell {
    background-color: #333333;
    font-size: 15px;
    font-weight: 500;
  }
  
  .el-table th.el-table__cell.is-leaf,
  .el-table td.el-table__cell {
    border-bottom: 1px solid $border;
  }
  
  .el-table thead {
    color: $grey40;
  }
  
  .el-table__body tr:hover > td.el-table__cell {
    background-color: transparent;
  }
  
  .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell,
  .el-table--striped:hover
    .el-table__body
    tr.el-table__row--striped:hover
    td.el-table__cell {
    background: #ffffff32;
  }
  
  .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #444444;
  }
  
  .el-table__inner-wrapper::before {
    background-color: transparent;
  }
  
  @media screen and (max-width: 500px) {
    .missing-bottle-button {
      margin-top: 8px;
    }
  
    .bottles-missing {
      align-items: start;
    }
  
    .buy-sell-search {
      height: 140px;
    }
  
    .background {
      position: relative;
      max-width: 100%;
      top: -40%;
    }
  
    .find-products {
      flex-wrap: wrap;
      margin: auto 10px;
  
      p {
        margin-bottom: 10px;
      }
    }
  
    .bottles-results-table {
      margin-top: 10px!important;
    }
  
    .bottles-list {
      display: none;
    }
  
    .products-tel {
      display: block;
  
      .product-tel-card {
        border-radius: 12px;
        border: 1px solid $border;
        background: #444;
        padding: 10px;
        margin: auto 10px;
        margin-bottom: 10px;
  
        .product-tel-informations {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
  
          .ticker-maitre {
            margin-right: 10px;
          }
        }
  
        .live-orders {
          justify-content: space-between;
          align-items: center;
        }
  
        h4 {
          color: white;
          font-weight: 500;
          margin-bottom: 10px;
  
          button {
            margin-right: 3px;
            margin-bottom: 3px;
          }
        }
      }
    }
  
    .case-buy,
    .case-sell,
    .case-taker,
    .case-giver {
      width: 75px !important;
  
      button {
        margin: 0 !important;
        min-width: auto;
        width: 70px;
      }
    }
  
    .empty-order {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      border: 1px solid $border;
      background: #444;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
      height: 25px;
      margin-left: 0 !important;
  
      img {
        border-radius: 50px;
        background: #ffffff33;
        margin-right: 3px;
      }
  
      p {
        color: $grey40;
        font-size: 15px;
        font-weight: 500;
      }
    }
  
    .case-giver {
      margin-left: 0!important;
  
      .empty-order {
        img {
          transform: scaleX(-1);
        }
      }
    }
  
    .back-to-history {
      margin-top: 10px!important;
    }
  
    .filter-by {
      flex-direction: column;
      margin-right: 10px;
  
      .btn-reset-filters {
        margin-bottom: 10px!important;
        margin-right: 0!important;
      }
    }
  
    .current-filters, .current-filters-no-results {
      display: flex;
      justify-content: left;
      flex-wrap: wrap;
      margin: 10px 0 5px 0;
      width: 95%;
  
      .el-tag {
        margin-bottom: 5px;
      }
    }
  
    .current-filters-no-results {
      margin-left: 10px;
      margin-top: 0;
    }
  }
</style>
  