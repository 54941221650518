<template>
    <div class="search-bottler-container">
      <searchResult />
    </div>
</template>
    
<script>
import { mapGetters, mapActions } from 'vuex';
import searchResult from '@/components/modules/searchResults.vue'

export default {
  components: {
    searchResult
  },
  data() {
    return {
      filters: {
        spirit_type: null,
        country: null,
        age: null,
        vintage: null,
        single_cask: false
      }
    }
  },
  computed: {
    ...mapGetters({
      getBottler: 'getBottler'
    })
  },
  methods: {
    ...mapActions({
      search: 'autocompleteSearchAuth'
    }),
  },
  mounted() {
    const payload = {
      type: 'bottlerPage',
      search: this.$route.params.name,
      spirit_type: null,
      filters: this.filters,
      bottler: this.$route.params.name
    }

    this.search(payload);
  }
};
</script>
  
<style scoped lang="scss">
@import '@/assets/styles/root.scss';

@media screen and (max-width: 500px) {

}
</style>